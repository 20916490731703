<template>
    <DropdownMenuSubTrigger
        v-bind="forwardedProps"
        :class="
            cn(
                'flex cursor-pointer justify-between gap-2 select-none items-center rounded-sm py-3 px-6 text-sm outline-none focus:bg-gray-100 data-[state=open]:bg-gray-100 dark:focus:bg-gray-800 dark:data-[state=open]:bg-gray-800',
                props.class
            )
        ">
        <slot />
        <i class="inline-block h-4 w-4 fass fa-chevron-right"></i>
    </DropdownMenuSubTrigger>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { DropdownMenuSubTrigger, type DropdownMenuSubTriggerProps, useForwardProps } from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps<DropdownMenuSubTriggerProps & { class?: HTMLAttributes["class"] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>
